@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap");

.clip-contents {
	overflow: hidden;
}

.flex-col-hstart-vstart {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.frame-1 {
	padding: 0px 34px 0px 66px;
	box-sizing: border-box;
	background-color: rgba(0, 0, 0, 0.47);
	min-width: 100%;
	height: 42px;
}

.flex-row-vcenter {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.flex-hcenter {
	justify-content: center;
}

.group-378 {
	height: 100%;
	width: 100%;
}

.txt-122 {
	font-size: 14px;
	font-family: Inter, sans-serif;
	font-weight: 600;
	color: rgba(255, 255, 255, 1);
	text-align: center;
	width: 64px;
	height: 19px;
	word-wrap: break-word;
	margin-right: 53px;
}

.txt-355 {
	font-size: 14px;
	font-family: Inter, sans-serif;
	font-weight: 600;
	color: rgba(255, 255, 255, 1);
	text-align: center;
	width: 64px;
	height: 19px;
	word-wrap: break-word;
	margin-right: 809px;
}

.untitled-artwork-11 {
	width: 50px;
	height: auto;
}

.connector {
	position: absolute;
	right: 15px;
}

nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100vw;
	z-index: 150;
	padding: 1rem 3rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0.2rem;
	flex-wrap: nowrap;
	background-color: rgba(11, 23, 34, 0.7764705882);
}

img {
	height: 3.5rem;
	width: 3.5rem;
}
